import { StaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import React from "react"
import { FaCalendarAlt } from 'react-icons/fa'
import { FaPhone } from 'react-icons/fa'
import $ from "jquery"


function changeActive(){
  $(".form").toggleClass("expanded");
  $('body').toggleClass('formExpanded');
}

export default () => (
  <StaticQuery query={ graphql`
          query CompanyQuery {
            sanityCompanyInfo {
              phone
              companyTagline
              primarycolor{
                hex
            }
            secondarycolor{
                hex
            }
            accentcolor{
                hex
            }
              logo {
                asset {
                  fluid(maxWidth: 700) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        `} 

        
        render={data => (
          <header>
              <div className="header-inner">
              </div>
          </header>
        )}
  />
)





/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Helmet from 'react-helmet'
import Image from "gatsby-image"
//import Form from "./form"
import "./layout.css"
import { FaCalendarAlt } from 'react-icons/fa'
import $ from "jquery"
import jQuery from 'jquery'
import ConversionLoggingInit from '../util/conversionLogging.js';



ConversionLoggingInit.init({env: 'prod'});



const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
  query SiteTitleQuery($slug: String) {
    site {
      siteMetadata {
        title
      }
    }
    sanityCompanyInfo {
      companyname
      phone
      logo{
        asset{
          fluid{
            ...GatsbySanityImageFluid
            src
          }
        }
      }
      favicon{
        asset{
          fluid{
            ...GatsbySanityImageFluid
            src
          }
        }
      }
      primarycolor{
        hex
    }
    secondarycolor{
        hex
    }
    accentcolor{
        hex
    }
      analytics
      clicky
      remarketing
    }
    sanityPages(slug: {current: {eq: $slug}}) {
          pagetitle
          slug{
            current
          }
      }
  }
`)
  return (
    <>
    
    <Helmet>
        <meta property="og:title" content={data.sanityCompanyInfo.companyname + " | " + data.sanityPages.pagetitle} />
    {data.sanityCompanyInfo.analytics ? (
          <script async className="AnalyticsCode" src={`https://www.googletagmanager.com/gtag/js?id=${data.sanityCompanyInfo.analytics}`}/> 
          
          ) : null}

          {data.sanityCompanyInfo.analytics ? (
              <script>
                {`window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                        
                  gtag('config', '${data.sanityCompanyInfo.analytics}');
                `}
              </script>
            ) : null}

          {data.sanityCompanyInfo.remarketing ? (
          <script async className="RemarketingCode" src={`https://www.googletagmanager.com/gtag/js?id=${data.sanityCompanyInfo.remarketing}`}/> ) : null}

          {data.sanityCompanyInfo.remarketing ? (
            <script>{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${data.sanityCompanyInfo.remarketing}');
            `}
          </script>

          ) : null}
          
          <script type="text/javascript">
                {`var SETUP_VS_LP = function(){
                    INIT_VS_LP({
                        env: 'prod'
                    });
                };`}
					    </script>
					    <script src="https://s3.amazonaws.com/vs.static-files/vs_lp_conv_bundle.js"  async defer onLoad={`SETUP_VS_LP`}></script>
              
              <script type="text/javascript" src="https://rw1.calls.net/euinc/number-changer.js"></script>

              <script>{`var clicky_site_ids = clicky_site_ids || []; clicky_site_ids.push(${data.sanityCompanyInfo.clicky});`}</script>
              <script async src="//static.getclicky.com/js"></script>
    </Helmet>
    
    <script type="text/javascript">{`
        var script = document.createElement('script');
        script.async = true; script.type = 'text/javascript';
        var target = 'https://www.clickcease.com/monitor/stat.js';
        script.src = target;var elem = document.head;elem.appendChild(script);
        
      `}</script>
      <noscript>
        <a href='https://www.clickcease.com' rel='nofollow'><img src='https://monitor.clickcease.com/stats/stats.aspx' alt='ClickCease'/></a>
        </noscript>
    <Header siteTitle={data.site.siteMetadata.title} />

      <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}



export default Layout
